<template>
  <div style="width: 100%">
    <div
      v-if="id && t === '1'"
      class="policy-read"
      v-html="pageHtml"
    />
    <iframe
      v-if="id && t === '2' && fileType === 1"
      ref="pdf"
      :src="previewUrl"
      width="100%"
    />
    <div
      v-if="id && t === '2' && fileType === 2"
      ref="word"
      class="word"
    />
  </div>
</template>

<script>
import { renderAsync } from 'docx-preview'
window.JSZip = require('jszip')
import { GetRegimeById, RemigeReadFile } from '@/api/regime/regime'
import { getEnterpriseInfo } from '@/api/enterprise/enterprise'
export default {
  data() {
    return {
      id: this.$route.query.id,
      t: this.$route.query.t,
      pageHtml: '',
      appName: '',
      previewUrl: '',
      fileType: 1, // 1 pdf 2 word
    }
  },
  created() {
    document.body.className = 'bg-white'
  },
  mounted() {
    this.getEnterpriseInfo()
    this.previewByContent()
  },
  methods: {
    getEnterpriseInfo() {
      getEnterpriseInfo().then(res => {
        const enterpriseInfo = { ...res.data.data }
        this.appName = enterpriseInfo.shortName
        document.title = `${this.appName}— 个人信息合规管理平台`
      })
    },
    previewByContent() {
      if (this.t === '1') {
        GetRegimeById({ id: Number(this.$route.query.id) }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.pageHtml = resData.data.content
          }
        })
      } else {
        RemigeReadFile(Number(this.$route.query.id)).then(res => {
          const resData = res.data
          const fileType = resData.type
          if (fileType === 'application/octet-stream') {
            this.fileType = 2
            this.$nextTick(() => {
              console.log(this.$refs.word)
              renderAsync(resData, this.$refs.word)
            })
          } else {
            this.fileType = 1
            this.previewUrl = window.URL.createObjectURL(
                new Blob([resData], { type: 'application/pdf' }),
            )
            this.$nextTick(() => {
              this.$refs.pdf.height = document.documentElement.clientHeight
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
.bg-white {
  background: #ffffff !important;
}
.policy-read {
  //width: 1200px;
  width: 1200px;
  margin: 20px auto 0 auto;
  table {
    width: 100%;
    table-layout: fixed;
    box-sizing: border-box;
    border-spacing: 0;
    page-break-inside: avoid !important;
    border-collapse: collapse;
  }
  td,
  th {
    box-sizing: border-box;
    border: 1px solid #666666;
    page-break-inside: avoid !important;
  }
}
</style>
